// third-party
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import PhoneIcon from '@mui/icons-material/Phone';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import SchoolIcon from '@mui/icons-material/School';
import TextsmsIcon from '@mui/icons-material/Textsms';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ReplaceableFormattedMessage from '../generic-components/ReplaceableFormattedMessage'
import EmailIcon from '@mui/icons-material/Email';
// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};


// const country = JSON.parse(localStorage.getItem('userData'))?.country;

// const academySideBar = {
//     id: 'academy',
//     title: <FormattedMessage id="Academy" />,
//     type: 'item',
//     url: '/academy',
//     icon: SchoolIcon,
//     breadcrumbs: true
// }
// ==============================|| Sidebar Items ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'main-panel',
            title: <FormattedMessage id="Main panel" />,
            type: 'item',
            url: '/main-panel',
            icon: LocationOnIcon,
            breadcrumbs: false
        },
        {
            id: 'patients',
            title: <FormattedMessage id="Patients" />,
            type: 'item',
            url: '/patients',
            icon: GroupIcon,
            breadcrumbs: false
        },
        {
            id: 'profile-settings',
            title: <FormattedMessage id="Edit Profile" />,
            type: 'item',
            url: '/profile-settings',
            icon: PersonIcon,
            breadcrumbs: true
        },
        // {
        //     id: 'calendar',
        //     title: <FormattedMessage id="calendar" />,
        //     type: 'item',
        //     url: '/calendar',
        //     icon: DateRangeIcon,
        //     breadcrumbs: true
        // },
        {
            id: 'gallery',
            title: <FormattedMessage id="Gallery" />,
            type: 'item',
            url: '/gallery',
            icon: CollectionsBookmarkIcon,
            breadcrumbs: true
        },
        {
            id: 'promotions',
            title: <FormattedMessage id="Promotions" />,
            type: 'item',
            url: '/promotions',
            icon: ReceiptLongIcon,
            breadcrumbs: true
        },
        // {
        //     id: 'store',
        //     title: <FormattedMessage id="Store" />,
        //     type: 'item',
        //     url: '/store',
        //     icon: LocalGroceryStoreIcon,
        //     breadcrumbs: true
        // },
         {
             id: 'academy',
             title: <FormattedMessage id="Academy" />,
             type: 'item',
             url: '/academy',
             icon: SchoolIcon,
             breadcrumbs: true
         }
        ,
        {
            id: 'clinical',
            title: <FormattedMessage id="Clinical Preferences" />,
            type: 'item',
            url: '/clinical',
            icon: LocalHospitalIcon,
            breadcrumbs: true
        },
        {
            id: 'appointments',
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            url: '/appointments',
            icon: DateRangeIcon,
            breadcrumbs: true
        },
        {
            id: 'notifications',
            title: <FormattedMessage id="Notifications" />,
            type: 'item',
            url: '/notifications',
            icon: TextsmsIcon,
            breadcrumbs: true
        },
        {
            id: 'messages',
            title: <FormattedMessage id="My Messages" />,
            type: 'item',
            url: '/messages',
            icon: EmailIcon,
            breadcrumbs: true
        },
        {
            id: 'contact',
            title: <FormattedMessage id="Contact" />,
            type: 'item',
            url: '/contact',
            icon: PhoneIcon,
            breadcrumbs: true
        },
    ]
};

export default other;
