import React, { useEffect, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const ReplaceableFormattedMessage = ({ id, toReplace, replacement }) => {
  const messageRef = useRef(null);

  useEffect(() => {
    if (messageRef.current) {
      const originalText = messageRef.current.textContent;
      const newText = originalText.replace(toReplace, replacement);
      messageRef.current.textContent = newText;
    }
  }, []);

  return <span ref={messageRef}><FormattedMessage id={id} /></span>;
};


// <ReplaceableFormattedMessage id="Send to Orthero" toReplace="cChangedCc" replacement="samet" />

export default ReplaceableFormattedMessage;
