import { lazy } from 'react';
import React from 'react';
// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/dashboard-list')));
const CreatePatient = Loadable(lazy(() => import('../views/create-patient')));
const CreateHerotainCase = Loadable(lazy(() => import('../views/create-herotain-case')));
const ProfileSettings = Loadable(lazy(() => import('../views/profile-settings')));
const Contact = Loadable(lazy(() => import('../views/contact')));
const Promotions = Loadable(lazy(() => import('../views/promotions')));
const Clinical = Loadable(lazy(() => import('../views/clinical')));
const Patients = Loadable(lazy(() => import('../views/patients')));
const Calendar = Loadable(lazy(() => import('../views/calendar')));
const OrtheroGallery = Loadable(lazy(() => import('../views/gallery')));
const Academy = Loadable(lazy(() => import('../views/academy')));
const Viewer = Loadable(lazy(() => import('../views/viewer')));
const Viewer2 = Loadable(lazy(() => import('../views/viewer2')));
const CaseDetail = Loadable(lazy(() => import('../views/casedetail')));
const Store = Loadable(lazy(() => import('../views/store')));
const Notifications = Loadable(lazy(() => import('../views/notifications')));
const AddRefinement = Loadable(lazy(() => import('../views/add-refinement')));
const AddHerotain = Loadable(lazy(() => import('../views/add-herotain')));
const ConfirmationHerotain = Loadable(lazy(() => import('../views/confirmation-herotain')));
const AnalyzerIframe = Loadable(lazy(() => import('../views/analyser-iframe')));
const OperationUpload = Loadable(lazy(() => import('../views/operation-upload')));
const OperationUploadTwo = Loadable(lazy(() => import('../views/operation-upload-two')));
const OperationUploadThree = Loadable(lazy(() => import('../views/operation-upload-three')));
const Messages = Loadable(lazy(() => import('../views/messages')));
const PageNotFound = Loadable(lazy(() => import('../views/page-not-found')));
const ErrorPage = Loadable(lazy(() => import('../views/error-page')));
const CaseNotFound = Loadable(lazy(() => import('../views/case-not-found')));
const OperationAttach = Loadable(lazy(() => import('../views/operation-attach')));
const PaymentRoute = Loadable(lazy(() => import('../views/payment-route')));
const FailureRoute = Loadable(lazy(() => import('../views/failure-route')));
const OperationUploadFour = Loadable(lazy(() => import('../views/operation-upload-four')));
const OperationUploadFive = Loadable(lazy(() => import('../views/operation-upload-five')));
const ContractUpload = Loadable(lazy(() => import('../views/contract-upload')));
const Appointment = Loadable(lazy(() => import('../views/appointment')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/main-panel',
            element: <SamplePage />
        },
        {
            path: '/viewer/:casePk/:pk',
            element: <Viewer />
        },
        {
            path: '/viewer2/:casePk/:pk',
            element: <Viewer2 />
        },
        {
            path: '/create-patient/:step',
            element: <CreatePatient />
        },
        {
            path: '/create-herotain-case/:step',
            element: <CreateHerotainCase />
        },
        {
            path: '/profile-settings',
            element: <ProfileSettings />
        },
        {
            path: '/promotions',
            element: <Promotions/>
        },
             {
            path: '/contact',
            element: <Contact/>
        },
        {
            path: '/clinical',
            element: <Clinical/>
        },
        {
            path: '/appointments',
            element: <Appointment/>
        },
        {
            path: '/patients',
            element: <Patients/>
        },
        {
            path: '/calendar',
            element: <Calendar/>
        },
        {
            path: '/gallery',
            element: <OrtheroGallery/>
        },
        {
            path: '/academy',
            element: <Academy/>
        },
        {
            path: '/casedetail/:pk',
            element: <CaseDetail/>
        },
        {
            path: '/notifications',
            element: <Notifications/>
        },
        {
            path: '/messages',
            element: <Messages/>
        },
        {
            path: '/store',
            element: <Store/>
        },
        {
            path: '/add-refinement',
            element: <AddRefinement/>
        },
        {
            path: '/add-herotain',
            element: <AddHerotain/>
        },
        {
            path: '/confirmation-herotain/:casePlanPkOrder',
            element: <ConfirmationHerotain/>
        },
        {
            path: '/analyser-iframe/:deneme',
            element: <AnalyzerIframe/>
        },
        {
            path: '/operation-upload/:casePk/:casePlanPk/:analysisPk',
            element: <OperationUpload/>
        },
        {
            path: '/operation-upload-two/:casePk/:casePlanPk',
            element: <OperationUploadTwo/>
        },
        {
            path: '/operation-upload-three/:casePk/:casePlanPk',
            element: <OperationUploadThree/>
        },
        {
            path: '/operation-attach/:userPk/:planPk/:caseLink/:target',
            element: <OperationAttach/>
        },
        {
            path: '/success/:url_param/',
            element: <PaymentRoute/>
        },
        {
            path: '/fail/:url_param/',
            element: <FailureRoute/>
        },
        {
            path: '*',
            element: <PageNotFound/>
        },
        {
            path: '/error-page',
            element: <ErrorPage/>
        },
        {
            path: '/operation-upload-four/:casePk/:casePlanPk/:analysisPk',
            element: <OperationUploadFour/>
        },
        {
            path: '/operation-upload-five/:casePk/:casePlanPk/:analysisPk',
            element: <OperationUploadFive/>
        },
        {
            path: '/contract-upload/:doctorPk/:campaignPk/',
            element: <ContractUpload/>
        },
        {
            path: '/case-not-found',
            element: <CaseNotFound/>
        },
    ]
};

export default MainRoutes;
