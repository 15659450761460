import React from 'react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player'

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    outline: "none",
    boxShadow: 24,
    p: 4,
  };
  
const NewInfo = ( { newInfo,setNewInfo } ) => {

  return (
       <Modal
                        open={newInfo}
                        onClose={() => setNewInfo(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography textAlign="center" fontSize="18px" color="#663BB9"><FormattedMessage id="training video" /></Typography>
                            <br />
                            <ReactPlayer
                                url={JSON.parse(localStorage.getItem('userData'))?.country === "0" ? "https://www.youtube.com/playlist?list=PLVy1FISGQ8e6P2EPBmRTfYCLbBB0rtz-E" : "https://www.youtube.com/playlist?list=PLVy1FISGQ8e57BZkGP3hyfnLIBV4ZE8aT"}
                                width="100%"
                                height="600px"
                                controls={true}
                            />
                        </Box>
                    </Modal>
  )
}

export default NewInfo