import axios from 'axios';
import jwtDecode from 'jwt-decode';

let isRefreshing = false;
let failedQueue = [];


const verifyToken = (refreshToken) => {
  if (!refreshToken) {
    return false;
  }
  const decoded = jwtDecode(refreshToken);
  return decoded.exp > Date.now() / 1000;
};


const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
        .then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        })
        .catch(err => Promise.reject(err));
      }

      isRefreshing = true;

      const refreshToken = window.localStorage.getItem("refreshToken");
      
      if(!refreshToken || !verifyToken(refreshToken)){
        window.localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(error);
      }

      try {
        const { data } = await axios.post('/api2/v2/login/refresh/', { refresh: refreshToken });
        const newAccessToken = data.access;

        window.localStorage.setItem("serviceToken", newAccessToken);
        window.localStorage.setItem("refreshToken",data.refresh);

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
        processQueue(null, newAccessToken);
        originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
        
        return axios(originalRequest);
       
      } catch (refreshError) {
        window.localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false; 
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
