import React, { useState } from "react";
import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// third-party

import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management

import { LOGIN, LOGOUT } from "../store/actions";
import accountReducer from "../store/accountReducer";

// project imports
import Loader from "../ui-component/Loader";
import axios from "axios";
const chance = new Chance();

// constant

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};


// function isMac() {
//   var platform = window.navigator.platform;
//   var userAgent = window.navigator.userAgent;

//   return platform.toUpperCase().indexOf('MAC') >= 0 ||
//          (platform.toUpperCase().indexOf('IOS') >= 0 && userAgent.indexOf('Mac') != -1);
// }


const verifyToken = (serviceToken) => {
  console.log("bakıyoruz")
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);

  /**

     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.

     */

  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setRefreshToken = (refreshToken) => {
  if(refreshToken){
    localStorage.setItem("refreshToken",refreshToken)
  }else{
    localStorage.removeItem("refreshToken");
  }
}
// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const [open, setOpen] = React.useState(false);
  const [brandData, setBrandData] = useState(null);


  const navigate = useNavigate();

  const azureSasToken = async () => {
    if (!window.location.href.includes("new/login")) {
      try {
        const response = await axios.get("/api2/v2/onlySasToken/");
        localStorage.setItem("sas", response.data);
        localStorage.setItem("sas_update", Date.now().toString());
      } catch (err) {
        console.log(err);
      }
    }
  };

  const [isMobile, setisMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

  const [sasReload, setSasReload] = useState(0);

  const urlNavigator = () => {
    if (
      window.location.href.includes("?next=/en/") ||
      window.location.href.slice(-4).toLowerCase().includes("new")
    ) {
      navigate("/main-panel");
    }
  };

  let sasInit = setTimeout(
    () => localStorage.getItem("userData") && setSasReload(sasReload + 1),
    600000
  );

  useEffect(() => {
    if (window.location.href.includes('find-doctor')) {
      localStorage.clear();
    }
    if (!window.location.href.includes('find-doctor')) {
      if (window.location.href.includes('new/viewer2')) {
        localStorage.setItem("userData", JSON.stringify({}));
      }
      let hiJack = window.localStorage.getItem("hiJack");
      let token_doc = window.localStorage.getItem("token");
      if (hiJack) {
        const hiJacker = async (hiJack, token_doc) => {
          const response = await axios.post("/api2/v2/auth/login/", {
            hiJack,
            token_doc,
          });
          const { serviceToken, user, status } = response.data;

          // console.log(user,serviceToken,status)

          // if (response.data.user['role'] == "doctor") {
          setSession(serviceToken);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
          localStorage.setItem("userData", JSON.stringify(response.data.user));
          localStorage.removeItem("hiJack");
          localStorage.removeItem("token");
          window.location.reload();

          azureSasToken();
          // }
        };

        hiJacker(hiJack, token_doc);
      } else {
        // console.log("HiJack başarısız. Token ve hijack doğrulamanız silindi.. Tekrar Deneyin.")
        localStorage.removeItem("hiJack");
        localStorage.removeItem("token");
      }

      const init = async () => {
        if (window.location.href.includes('new/login')) {
          localStorage.removeItem('urlPart');
        }
        // logoutChannel.addEventListener('message', function(event) {
        //   if (event.data.action === 'logout') {
        //     setSession(null);
        //     localStorage.removeItem('userData');
        //     dispatch({
        //       type: LOGOUT,
        //     });
        //     logoutChannel.close();
        //   }
        // });

        if (window.location.href.includes('new/viewer2')) {
          const urlParams = new URLSearchParams(window.location.search);
          const serviceToken = urlParams.get('serviceToken');
          setSession(serviceToken);
          const response2 = await axios.get("/api2/v2/demoaccount/me/");
          localStorage.setItem("userData", JSON.stringify(response2.data.data.user));

          localStorage.setItem("viewerTools", JSON.stringify({
            isViewsWork: false,
            isBg: false,
            isFull: false,
            isShare: false,
            isChat: false,
            isPhotos: false,
            isXrays: false,
            isRx: false,
            isTmt: false,
            isStagging: false,
            isClireq: false,
            isFeatures: false,
            isBolton: false,
            isMove: false,
            isAttach: false,
            isMeasure: false,
            isClipping: false,
            isOcclusal: false,
            isThreed: false,
            isPlan: false,
          }))

          try {
            const serviceToken = window.localStorage.getItem("serviceToken");
            const refreshToken = window.localStorage.getItem("refreshToken");

            if (serviceToken && verifyToken(serviceToken)) {
              setSession(serviceToken);
              const response = await axios.get("/api2/v2/accunt/me/");
              const { user } = response.data;

              dispatch({
                type: LOGIN,
                payload: {
                  isLoggedIn: true,
                  user,
                },
              });
            } else {
              if(refreshToken && verifyToken(refreshToken)){
                const refreshSession  = await axios.post("/api2/v2/login/refresh/",{
                 refresh: refreshToken 
                })
                setSession(refreshSession.data.access);
                setRefreshToken(refreshSession.data.refresh);
                const response = await axios.get("/api2/v2/accunt/me/");
                const { user } = response.data;
                dispatch({
                  type: LOGIN,
                  payload: {
                    isLoggedIn: true,
                    user,
                  },
                });
                
              }else{
                navigate("/login");
                dispatch({
                  type: LOGOUT,
                });
                window.localStorage.clear();
              }
            }
          } catch (err) {
            console.log("catche düştük")
            console.error(err);
            navigate("/login");
            dispatch({
              type: LOGOUT,
            });
            window.localStorage.clear();
          }

        }
        else {
          try {
            const serviceToken = window.localStorage.getItem("serviceToken");
            const refreshToken = window.localStorage.getItem("refreshToken");

            if (serviceToken && verifyToken(serviceToken)) {
              
              setSession(serviceToken);
              const response = await axios.get("/api2/v2/accunt/me/");
              const { user } = response.data;
            
              
              dispatch({
                type: LOGIN,
                payload: {
                  isLoggedIn: true,
                  user,
                },
              });
            } else {
              if(refreshToken && verifyToken(refreshToken)){ 
               try {
                  const refreshSession = await axios.post("/api2/v2/login/refresh/",{ 
                    refresh: refreshToken  
                  })
                  setSession(refreshSession.data.access); 
                  setRefreshToken(refreshSession.data.refresh); 
                  const response = await axios.get("/api2/v2/accunt/me/"); 
                  const { user } = response.data; 
                  dispatch({ 
                    type: LOGIN, 
                    payload: { 
                      isLoggedIn: true,  
                      user, 
                    }, 
                  });
                } catch (error) {
                navigate("/login"); 
                dispatch({ 
                  type: LOGOUT, 
                }); 
                localStorage.clear(); 
               }
              }else{  
                navigate("/login"); 
                dispatch({ 
                  type: LOGOUT, 
                }); 
                localStorage.clear(); 
              } 
            }
          } catch (err) {
            console.error(err);
            navigate("/login"); 
            dispatch({
              type: LOGOUT,
            });
            localStorage.clear(); 
          }
        }


        azureSasToken();
        urlNavigator();
        // loginChannel.addEventListener('message', function(event) {
        //   if (event.data.action === 'login') {
        //   window.location.reload();
        //   }
        //   loginChannel.close();
        // });
      };

      // if(localStorage.hasOwnProperty('userData')){
      //   if(JSON.parse(localStorage.getItem('userData'))['role'] !== "doctor"){
      //     localStorage.clear();
      //     dispatch({
      //       type: LOGOUT,
      //     });
      //   }
      // }


      init();
    }
  }, [sasReload]);
  // }, [sasReload,logoutChannel,loginChannel]);

  const location = useLocation();

  useEffect(() => {


    const windowUrl =   window.location.origin;
     
    const manuelBrandData = {
      "colors":{
        "main-color": '#f2f4f6'
      },
      "documents":{
        "login_bg": windowUrl.includes("dr.ortohero") ? "https://testseffaf.blob.core.windows.net/devblob/login-back-ground.png" : "https://testseffaf.blob.core.windows.net/devblob/img/1.png"
      },
      "domain": windowUrl,
      "logo": windowUrl.includes("dr.ortohero") ? "https://testseffaf.blob.core.windows.net/devblob/ortohero-original-logo.png" : "https://seffafcdn.blob.core.windows.net/pics/orthero-logo.png",
      "name": windowUrl.includes("dr.ortohero") ? "Ortohero " : "Orthero"
    }
    
    const brandData = window.brandData ? window.brandData : manuelBrandData;

    localStorage.setItem('brand', JSON.stringify(brandData));

    const adjustZoom = () => {
      const isFirefox = typeof InstallTrigger !== 'undefined'; // Check for Firefox
      const path = window.location.href;
      const width = window.innerWidth;
      let zoomLevel;

      if (width < 1920 && width > 1745 && (!path.includes('new/login') && !path.includes('new/gallery'))) {
        document.body.style.zoom = 75 + "%"
      }
      else if (width > 1280 && (!path.includes('new/login') && path.includes('new/viewer'))) {
        zoomLevel = 0.8;
      } else if (width <= 1280 && (!path.includes('new/login') && path.includes('new/viewer'))) {
        zoomLevel = 0.64;
      }
      else if (width <= 1745 && width > 1536 && (!path.includes('new/login') && !path.includes('new/gallery'))) {
        document.body.style.zoom = 90 + "%"
      }
      else if (width <= 1536 && width > 1280 && (!path.includes('new/login') && !path.includes('new/gallery'))) {
        document.body.style.zoom = 77 + "%"
      }
      else if (width <= 1280 && width > 767 && (!path.includes('new/login') && !path.includes('new/gallery'))) {
        document.body.style.zoom = 65 + "%"
      }
      else if (isMobile && (!path.includes('new/login') && !path.includes('new/gallery'))) {
        document.body.style.zoom = 65 + "%"
      }
      else {
        document.body.style.zoom = 100 + "%"
      }

      if (isFirefox) {
        document.body.style.transform = `scale(${zoomLevel})`;
        document.body.style.transformOrigin = 'top left';
        document.documentElement.style.setProperty('height', `${100 / zoomLevel}vh`);
        document.documentElement.style.setProperty('width', `${100 / zoomLevel}vw`);
        window.scrollTo(0, 0);

      } else {
        document.body.style.zoom = `${zoomLevel * 100}%`;
      }
    };

    adjustZoom();
    window.addEventListener('resize', adjustZoom);
    const urlParts = window.location.href.split("/");
    const urlPart = urlParts[urlParts.length - 1];
    const afterCreatePatient = urlParts.indexOf("create-patient") !== -1;

    if (afterCreatePatient && !isNaN(urlPart)) {
      localStorage.setItem("urlPart", urlPart);
    }
    else {
      if (localStorage.hasOwnProperty("urlPart") && !window.location.href.includes('new/login')) {
        setOpen(true)
      }
    }
    if (sessionStorage.hasOwnProperty('modal')) {
      setTimeout(() => {
        sessionStorage.removeItem('modal');
      }, 2500);
      if (!window.location.href.includes('login')) {
        setOpen(true);
      }
    }


    const userData = localStorage.getItem("userData");
    const sasToken = localStorage.getItem("sas");
    let sasUpdate = localStorage.getItem("sas_update");

    if (sasUpdate) sasUpdate = parseInt(sasUpdate);

    if (!userData) {
      dispatch({
        type: LOGOUT,
      });

      return;
    }

    if (!sasToken || !sasUpdate || Date.now() > sasUpdate + 600000) {
      azureSasToken();
    }
  }, [location]);

  const login = async (email, password) => {
    const response = await axios.post("/api2/v2/auth/login/", {
      email,
      password,
    });
    const { serviceToken, user, status, refresh } = response.data;
    localStorage.setItem("userData", JSON.stringify(response.data.user));
    if (response.data.user["role"] == "doctor") {

      setSession(serviceToken);
      setRefreshToken(refresh); 
      // loginChannel.postMessage({ action: 'login' });

      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user,
        },
      });

      localStorage.setItem("userData", JSON.stringify(response.data.user));
      // window.location.replace("tr/new/login");
      azureSasToken();
    } else {
      const response = await axios.post("/api2/v2/personelaccess/", {
        email,
        password,
      });
      // console.log("hereee", response.data.user);
      setSession(serviceToken);
      setRefreshToken(refresh); 
      localStorage.setItem("logged_in", true);
      localStorage.setItem("serviceToken", serviceToken);
      localStorage.getItem("userData");
      window.location.replace("/");
      // window.open('/admindashboard/')
      // window.location.href = "http://localhost:8000/admindashboard/";
      // window.location.replace(`/personelaccess/`);

      azureSasToken();
    }
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName,
    });

    let users = response.data;

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    window.localStorage.setItem("users", JSON.stringify(users));
  };

  const logout = () => {
    clearTimeout(sasInit);
    setSession(null);
    setRefreshToken(null); 
    dispatch({ type: LOGOUT });
    localStorage.removeItem("userData");
    localStorage.removeItem('urlPart');
    // logoutChannel.postMessage({ action: 'logout' });
  };

  // let modals = {
  //   'urlPart':<CaseCreateReminder setOpen = {setOpen} />,
  //   'langChange': <Detect setOpen = {setOpen} />
  //  }

  // const modalOpenHandler = modal => modals[modal];

  const resetPassword = (email) => console.log(email);

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default JWTContext;
